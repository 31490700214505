<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <SchematicRenderer v-if="url !== ''" :height="height" :width="width" :url="url" />
</template>

<script>
import SchematicRenderer from './components/SchematicRenderer.vue'

export default {
  name: 'App',
  data () {
    return {
      url: '',
      height: 300,
      width: 300,
    }
  },
  mounted () {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    this.url = params.url
    this.width = Number(params.width)
    this.height = Number(params.height)
  },
  components: {
    SchematicRenderer
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
