import {
	MathUtils,
	Spherical,
	Vector3
} from 'three'

var FirstPersonControls = function (object, domElement) {

	if (domElement === undefined) {

		console.warn('THREE.FirstPersonControls: The second parameter "domElement" is now mandatory.');
		domElement = document;

	}

	this.object = object;
	this.domElement = domElement;

	// API

	this.enabled = true;

	this.movementSpeed = 1.0;
	this.lookSpeed = 0.005;
	this.lookSpeedTouch = 0.005;

	this.lookVertical = true;
	this.autoForward = false;

	this.activeLook = false;
	this.touching = false;
	this.moving = false;

	this.heightSpeed = false;
	this.heightCoef = 1.0;
	this.heightMin = 0.0;
	this.heightMax = 1.0;

	this.constrainVertical = false;
	this.verticalMin = 0;
	this.verticalMax = Math.PI;

	this.mouseDragOn = false;

	// internals

	this.autoSpeedFactor = 0.0;

	this.mouseX = 0;
	this.mouseY = 0;

	this.moveForward = false;
	this.moveBackward = false;
	this.moveLeft = false;
	this.moveRight = false;

	this.viewHalfX = 0;
	this.viewHalfY = 0;

	// private variables

	var lat = 0;
	var lon = 0;
	var prevTouch = null;

	var lookDirection = new Vector3();
	var spherical = new Spherical();
	var target = new Vector3();

	//

	if (this.domElement !== document) {

		this.domElement.setAttribute('tabindex', - 1);

	}

	//

	this.handleResize = function () {

		if (this.domElement === document) {

			this.viewHalfX = window.innerWidth / 2;
			this.viewHalfY = window.innerHeight / 2;

		} else {

			this.viewHalfX = this.domElement.offsetWidth / 2;
			this.viewHalfY = this.domElement.offsetHeight / 2;

		}

	};

	this.onTouchStart = function (event) {
		this.touching = true;
		this.onMouseDown.apply(this, [event]);
	}

	this.onMouseDown = function (event) {

		if (this.domElement !== document) {

			this.domElement.focus();

		}

		event.preventDefault();
		event.stopPropagation();

		this.activeLook = true;
	};

	this.onTouchEnd = function (event) {
		this.touching = false;
		this.onMouseUp.apply(this, [event]);
		prevTouch = null;
	};

	this.onMouseUp = function (event) {

		event.preventDefault();
		event.stopPropagation();

		this.activeLook = false;
		this.mouseX = 0;
		this.mouseY = 0;
	};

	this.onMouseMove = function (event) {
		if (!this.activeLook) {
			return
		}

		if (this.touching) {
			const touch = event.touches[0];

			if (prevTouch) {
				const movementX = touch.pageX - prevTouch.pageX;
				const movementY = touch.pageY - prevTouch.pageY;

				this.mouseX = movementX * 50;
				this.mouseY = movementY * 50;
			}

			prevTouch = touch;
		} else {
			this.mouseX = Math.min(event.movementX * 20, 110);
			this.mouseY = Math.min(event.movementY * 20, 110);
		}
	};

	this.onKeyDown = function (event) {

		// event.preventDefault();

		switch (event.code) {

			case 'ArrowUp':
			case 'KeyW': this.moveForward = true; break;

			case 'ArrowLeft':
			case 'KeyA': this.moveLeft = true; break;

			case 'ArrowDown':
			case 'KeyS': this.moveBackward = true; break;

			case 'ArrowRight':
			case 'KeyD': this.moveRight = true; break;

			case 'Space':
			case 'KeyQ': this.moveUp = true; break;

			case 'ControlLeft':
			case 'KeyE': this.moveDown = true; break;
		}

		this.moving = true;
	};

	this.onKeyUp = function (event) {

		switch (event.code) {

			case 'ArrowUp':
			case 'KeyW': this.moveForward = false; break;

			case 'ArrowLeft':
			case 'KeyA': this.moveLeft = false; break;

			case 'ArrowDown':
			case 'KeyS': this.moveBackward = false; break;

			case 'ArrowRight':
			case 'KeyD': this.moveRight = false; break;

			case 'Space':
			case 'KeyQ': this.moveUp = false; break;

			case 'ControlLeft':
			case 'KeyE': this.moveDown = false; break;

		}

		this.moving = false;
	};

	this.lookAt = function (x, y, z) {

		if (x.isVector3) {

			target.copy(x);

		} else {

			target.set(x, y, z);

		}

		this.object.lookAt(target);

		setOrientation(this);

		return this;

	};

	this.update = function () {

		var targetPosition = new Vector3();

		return function update(delta) {

			if (this.enabled === false) return;

			if (this.heightSpeed) {

				var y = MathUtils.clamp(this.object.position.y, this.heightMin, this.heightMax);
				var heightDelta = y - this.heightMin;

				this.autoSpeedFactor = delta * (heightDelta * this.heightCoef);

			} else {

				this.autoSpeedFactor = 0.0;

			}

			var actualMoveSpeed = delta * this.movementSpeed;

			if (this.moveForward || (this.autoForward && !this.moveBackward)) this.object.translateZ(- (actualMoveSpeed + this.autoSpeedFactor));
			if (this.moveBackward) this.object.translateZ(actualMoveSpeed);

			if (this.moveLeft) this.object.translateX(- actualMoveSpeed);
			if (this.moveRight) this.object.translateX(actualMoveSpeed);

			if (this.moveUp) this.object.translateY(actualMoveSpeed);
			if (this.moveDown) this.object.translateY(- actualMoveSpeed);

			var actualLookSpeed = delta * (this.touching ? this.lookSpeedTouch : this.lookSpeed);

			if (!this.activeLook || this.moving) {

				actualLookSpeed = 0;

			}

			var verticalLookRatio = 1;

			if (this.constrainVertical) {

				verticalLookRatio = Math.PI / (this.verticalMax - this.verticalMin);

			}

			lon -= this.mouseX * actualLookSpeed;
			if (this.lookVertical) lat -= this.mouseY * actualLookSpeed * verticalLookRatio;

			lat = Math.max(- 85, Math.min(85, lat));

			var phi = MathUtils.degToRad(90 - lat);
			var theta = MathUtils.degToRad(lon);

			if (this.constrainVertical) {

				phi = MathUtils.mapLinear(phi, 0, Math.PI, this.verticalMin, this.verticalMax);

			}

			var position = this.object.position;

			targetPosition.setFromSphericalCoords(1, phi, theta).add(position);

			this.object.lookAt(targetPosition);
		};

	}();

	function contextmenu(event) {

		event.preventDefault();

	}

	this.dispose = function () {

		this.domElement.removeEventListener('contextmenu', contextmenu);
		this.domElement.removeEventListener('mousedown', _onMouseDown);
		this.domElement.removeEventListener('mousemove', _onMouseMove);
		this.domElement.removeEventListener('mouseup', _onMouseUp);

		this.domElement.removeEventListener('touchstart', _onTouchStart);
		this.domElement.removeEventListener('touchmove', _onMouseMove);
		this.domElement.removeEventListener('touchend', _onTouchEnd);

		window.removeEventListener('keydown', _onKeyDown);
		window.removeEventListener('keyup', _onKeyUp);

	};

	var _onMouseMove = bind(this, this.onMouseMove);
	var _onMouseDown = bind(this, this.onMouseDown);
	var _onMouseUp = bind(this, this.onMouseUp);
	var _onKeyDown = bind(this, this.onKeyDown);
	var _onKeyUp = bind(this, this.onKeyUp);
	var _onTouchStart = bind(this, this.onTouchStart);
	var _onTouchEnd = bind(this, this.onTouchEnd);

	this.domElement.addEventListener('contextmenu', contextmenu);
	this.domElement.addEventListener('mousemove', _onMouseMove);
	this.domElement.addEventListener('mousedown', _onMouseDown);
	this.domElement.addEventListener('mouseup', _onMouseUp);

	this.domElement.addEventListener('touchmove', _onMouseMove);
	this.domElement.addEventListener('touchstart', _onTouchStart);
	this.domElement.addEventListener('touchend', _onTouchEnd);

	window.addEventListener('keydown', _onKeyDown);
	window.addEventListener('keyup', _onKeyUp);

	function bind(scope, fn) {

		return function () {

			fn.apply(scope, arguments);

		};

	}

	function setOrientation(controls) {

		var quaternion = controls.object.quaternion;

		lookDirection.set(0, 0, - 1).applyQuaternion(quaternion);
		spherical.setFromVector3(lookDirection);

		lat = 90 - MathUtils.radToDeg(spherical.phi);
		lon = MathUtils.radToDeg(spherical.theta);

	}

	this.handleResize();

	setOrientation(this);

};

export { FirstPersonControls };
