<template>
  <div id="schematic-renderer"></div>
  <div class="actions" v-show="isTouchDevice">
    <button class="w">W</button>
    <button class="s">S</button>
    <button class="a">A</button>
    <button class="d">D</button>

    <button class="q">Q</button>
    <button class="e">E</button>
  </div>
  <div class="tips">
    <p>按住移动视角</p>
    <p v-show="!isTouchDevice">WASD 前进后退</p>
    <p v-show="!isTouchDevice">空格、Q、E 上升下降</p>
  </div>
</template>

<script>
import { WorldView, Viewer } from 'prismarine-viewer/viewer'
import { Vec3 } from 'vec3'
import * as THREE from 'three'
import { FirstPersonControls } from './FirstPersonControls'
import { Schematic } from 'prismarine-schematic'
import * as worldGenFn from 'prismarine-world'
import * as chunkGenFn from 'prismarine-chunk'
import { watch } from 'vue'

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

function getSchematic (url, cb) {
  const oReq = new XMLHttpRequest()
  oReq.open('GET', url, true)
  oReq.responseType = 'arraybuffer'
  oReq.onload = () => {
    cb(oReq.response)
  }
  oReq.send(null)
}

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    }
  },
  setup (props) {
    const clock = new THREE.Clock()

    const onData = async function (data) {
      const schem = await Schematic.read(Buffer.from(data), '1.16.4')

      const version = schem.version
      const viewDistance = 6
      const center = new Vec3(4, 65, 17)

      const World = worldGenFn(version)
      const Chunk = chunkGenFn(version)

      const generator = (x, y) => {
        if (y < 60) return 1
        return 0
      }
      const chunkGenerator = (chunkX, chunkZ) => {
        const chunk = new Chunk()
        for (let y = 0; y < 256; y++) {
          for (let x = 0; x < 16; x++) {
            for (let z = 0; z < 16; z++) {
              chunk.setBlockStateId(new Vec3(x, y, z), generator(chunkX * 16 + x, y, chunkZ * 16 + z))
            }
          }
        }
        return chunk
      }

      const world = new World(chunkGenerator)
      const worldView = new WorldView(world, viewDistance, center)

      await schem.paste(world, new Vec3(0, 60, 0))

      // Create three.js context, add to page
      const renderer = new THREE.WebGLRenderer()
      renderer.setPixelRatio(window.devicePixelRatio || 1)
      renderer.setSize(props.width, props.height)
      watch(props, () => {
        renderer.setSize(props.width, props.height)
      })
      document.querySelector('#schematic-renderer').appendChild(renderer.domElement)

      // Create viewer
      const viewer = new Viewer(renderer)
      viewer.setVersion(version)
      // Attach controls to viewer
      const controls = new FirstPersonControls(viewer.camera, renderer.domElement)
      controls.lookSpeed = 1.25
      controls.lookSpeedTouch = 1.25
      controls.movementSpeed = 10

      // Link WorldView and Viewer
      viewer.listen(worldView)
      viewer.setFirstPersonCamera(viewer.camera.position, 0, 0)
      // Initialize viewer, load chunks
      worldView.init(center)

      viewer.camera.position.set(center.x, center.y, center.z)
      // controls.update()

      // Browser animation loop
      const animate = () => {
        const d = clock.getDelta()
        window.requestAnimationFrame(animate)
        if (controls) controls.update(d)
        worldView.updatePosition(viewer.camera.position)
        renderer.render(viewer.scene, viewer.camera)
      }
      animate()

      if (isTouchDevice()) {
        document.querySelector('button.w').addEventListener('touchstart', () => controls.moveForward = true)
        document.querySelector('button.a').addEventListener('touchstart', () => controls.moveLeft = true)
        document.querySelector('button.s').addEventListener('touchstart', () => controls.moveBackward = true)
        document.querySelector('button.d').addEventListener('touchstart', () => controls.moveRight = true)
        document.querySelector('button.q').addEventListener('touchstart', () => controls.moveUp = true)
        document.querySelector('button.e').addEventListener('touchstart', () => controls.moveDown = true)

        document.querySelector('button.w').addEventListener('touchend', () => controls.moveForward = false)
        document.querySelector('button.a').addEventListener('touchend', () => controls.moveLeft = false)
        document.querySelector('button.s').addEventListener('touchend', () => controls.moveBackward = false)
        document.querySelector('button.d').addEventListener('touchend', () => controls.moveRight = false)
        document.querySelector('button.q').addEventListener('touchend', () => controls.moveUp = false)
        document.querySelector('button.e').addEventListener('touchend', () => controls.moveDown = false)
      }
    }

    // load and paste a schematic
    if (props.url) {
      getSchematic(props.url, onData)      
    }

    return {
      isTouchDevice: isTouchDevice()
    }
  }
}
</script>

<style scoped>
.tips {
  position: fixed;
  right: 1em;
  top: 1em;
  text-align: right;
}

.actions {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.actions button {
  display: block;
  position: absolute;
  width: 2em;
  height: 2em;
}

button.w {
  right: 4em;
  bottom: 4em;
}

button.a {
  right: 7em;
  bottom: 1em;
}

button.s {
  right: 4em;
  bottom: 1em;
}

button.d {
  right: 1em;
  bottom: 1em;
}

button.q {
  right: 10em;
  bottom: 4em;
}

button.e {
  right: 10em;
  bottom: 1em;
}
</style>